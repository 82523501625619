import Phaser, { AUTO, Scale } from 'phaser'
import Game from './scenes/Game'
import Preloader from './scenes/Preloader'



const config = {
	type: Phaser.AUTO,
	parent: 'stars',
	width: 1920,
	height: 1080,
	transparent: true,
	dom: {
		createContainer: true
	},
	physics: {
		default: 'arcade',
		arcade: {
			gravity: { y: 0 }
		},
	},
	scale: {
		parent: 'stars',
		mode: Phaser.Scale.RESIZE,
		autoCenter: Phaser.Scale.CENTER_BOTH
	},
	autoRound: true,
	scene: [Preloader, Game]
}
export default new Phaser.Game(config);
